<template>
  <div :class="containerClass">
    <strong
      :class="valueClass"
      :style="valueStyle"
    >
      <slot />
    </strong>
    <small
      v-if="hasLabelSlot"
      :class="labelClass"
    >
      <slot name="label" />
    </small>
    <small
      v-else
      :class="labelClass"
    >
      {{ label }}
    </small>
  </div>
</template>

<script>
export default {
  name: 'SnowDataPoint',

  props: {
    label: {
      type: String,
      default: '',
    },
    labelClassNames: {
      type: String,
      default: null,
    },
    leftAlign: {
      type: Boolean,
      default: false,
    },
    // Display as left aligned row on mobile and centered column on desktop
    responsiveRow: {
      type: Boolean,
      default: false,
    },
    // Always display as left aligned row
    row: {
      type: Boolean,
      default: false,
    },
    valueClassNames: {
      type: String,
      default: 'text-darkest-color',
    },
    valueStyle: {
      type: Object,
      default: null,
    },
  },

  computed: {
    containerClass() {
      if (this.row) {
        return 'tw-flex tw-flex-row tw-justify-start tw-items-center';
      }

      if (this.responsiveRow) {
        return [
          'tw-flex',
          'tw-flex-row lg:tw-flex-col',
          'tw-justify-start lg:tw-justify-center',
          'tw-items-center',
        ];
      }

      return [
        'tw-flex',
        'tw-flex-col',
        'tw-justify-center',
        !this.leftAlign ? 'tw-items-center' : 'tw-items-start',
      ];
    },

    hasLabelSlot() {
      return this.$slots.label;
    },

    labelClass() {
      let display = 'tw-block';

      if (this.row) {
        display = 'tw-inline-block';
      }
      else if (this.responsiveRow) {
        display = 'tw-inline-block lg:tw-block';
      }

      return [
        display,
        'lg:tw-pt-1.5',
        'tw-text-xs',
        'lg:tw-text-sm',
        'tw-font-normal',
        'text-dark-color',
        this.labelClassNames,
      ];
    },

    valueClass() {
      let marginRight = null;

      if (this.row) {
        marginRight = 'tw-mr-2';
      }
      else if (this.responsiveRow) {
        marginRight = 'tw-mr-4 lg:tw-mr-0';
      }

      return [
        'tw-text-xl',
        'lg:tw-text-2xl',
        'tw-font-medium',
        marginRight,
        this.valueClassNames,
      ];
    },
  },
};
</script>
